<template>
  <div>
    <el-card
      v-if="!showRoleEdit"
      class="card-box"
    >
      <!-- 页面内容 -->
      <div>
        <!-- 新增添加角色的抽屉 start-->
        <el-drawer
          ref="drawer"
          :title="form.id ? '编辑角色' : '创建角色'"
          :before-close="handleClose"
          :visible.sync="showAddEditDraw"
          direction="rtl"
          custom-class="demo-drawer"
          size="683px"
        >
          <div class="drawer-content">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-width="80px"
            >
              <el-form-item
                label="角色名称"
                prop="name"
              >
                <el-input
                  v-model="form.name"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item
                label="备注"
                prop="remarks"
              >
                <el-input
                  v-model="form.remarks"
                  placeholder="在这里输入角色说明便于记忆哦！"
                  autocomplete="off"
                  type="textarea"
                  :rows="4"
                  maxlength="128"
                  show-word-limit
                />
              </el-form-item>
            </el-form>
            <div class="drawer-footer">
              <el-button
                type="primary"
                @click="submitAddOrEdit"
              >
                {{
                  form.name ? "保 存" : "创 建"
                }}
              </el-button>
              <el-button @click="handleClose">
                取 消
              </el-button>
            </div>
          </div>
        </el-drawer>

        <el-drawer
          ref="drawer"
          title="应用用户组"
          :before-close="handleGroupClose"
          :visible.sync="showGroup"
          direction="rtl"
          custom-class="demo-drawer"
          size="683px"
        >
          <div class="drawer-content">
            <div
              class="el-tree-warp"
              style="border: 1px solid #ccc;margin-bottom: 20px;padding: 20px;"
            >
              <div
                v-for="(item, index) in groupList"
                :key="index"
                class="checkItem"
              >
                <el-checkbox
                  v-model="item.selected"
                  :true-label="1"
                  :false-label="0"
                  @click.stop.native="() => {}"
                  @change="selectedGroup(item)"
                />
                <font
                  :style="{
                    color: item.selected == 1 ? '#409EFF' : '',
                    'margin-left': '10px',
                    'font-size': '13px'
                  }"
                >
                  {{ item.name }}
                </font>
              </div>
            </div>
            <div class="drawer-footer">
              <el-button
                type="primary"
                @click="submitGroup"
              >
                保 存
              </el-button>
              <el-button @click="handleGroupClose">
                取 消
              </el-button>
            </div>
          </div>
        </el-drawer>
        <!-- 设置权限的抽屉 -->
        <!-- <el-drawer
          title="权限管理"
          :before-close="handleRoleClose"
          :visible.sync="showRoleDrawer"
          direction="rtl"
          custom-class="demo-drawer"
          ref="drawer"
          size="683px"
        >
          <div class="drawer-content">
            <div class="el-tree-warp">
              <div class="tree-area">
                <el-tree
                  ref="tree"
                  :data="data"
                  show-checkbox
                  node-key="permissionId"
                  :default-expand-all="true"
                  :props="defaultProps"
                  @check-change="handleCheckChange"
                ></el-tree>
              </div>
              <div class="checkbox-area">
                <el-checkbox
                  style="margin-left:43px"
                  v-model="checkedAll"
                  @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
              </div>
            </div>

            <div class="drawer-footer">
              <el-button @click="resetSelect">重 置</el-button>
              <el-button type="primary" @click="saveRole">保 存</el-button>
            </div>
          </div>
        </el-drawer> -->
        <!-- 新增添加角色的抽屉 end-->

        <!-- 按钮区域 start -->
        <el-button
          type="primary"
          size="medium"
          @click="addOrEditRole"
        >
          添加角色
        </el-button>
        <el-button
          type="danger"
          :disabled="selectedArr.length == 0"
          size="medium"
          @click="delMore"
        >
          批量删除
        </el-button>
        <!-- 按钮区域 end -->

        <!-- 表格区域 start -->
        <el-table
          v-loading="loading"
          v-tableHeight="{bottomOffset: 80}"
          :data="roleTableData"
          style="width: 100%;margin-top:10px"
          height="100px"
          :header-cell-style="styleObj"
          border
          :row-style="{height: '0'}"
          :cell-style="{padding: '0'}"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="40"
          />
          <el-table-column
            type="index"
            label="序号"
            width="55"
          />
          <el-table-column
            prop="name"
            label="角色名称"
            width="180"
          />
          <el-table-column
            prop="remarks"
            label="备注"
          />
          <el-table-column
            label="操作"
            width="280"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="addOrEditRole(scope.row)"
              >
                编辑
              </el-button>
              <!-- <el-button type="text" @click="handleRole(scope.row)"
                >权限管理</el-button
              > -->

              <el-button
                type="text"
                @click="handleGroupRole(scope.row)"
              >
                后台权限
              </el-button>

              <el-button
                type="text"
                @click="handleGroup(scope.row)"
              >
                应用用户组权限
              </el-button>

              <el-popconfirm
                :title="'确认删除 ' + scope.row.name + '吗？'"
                @confirm="roleDelete(scope.row)"
              >
                <el-button
                  slot="reference"
                  style="color:#D9001B;margin-left:10px"
                  type="text"
                >
                  删除
                </el-button>
              </el-popconfirm>
              <!-- <el-button
                style="color:#D9001B"
                type="text"
                @click="roleDelete(scope.row)"
                >删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 表格区域 end -->

        <!-- 分页区域 start -->
        <div style="text-align:right;margin-top:10px">
          <el-pagination
            :current-page="currentPage"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="userTotal"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <!-- 分页区域 end -->
      </div>
      <!-- 编辑的抽屉 -->
    </el-card>
    <role-permissions
      v-else
      :role-permession="rolePermession"
      @back="back"
    />
  </div>
</template>

<script>
import {
  rolesSettingList,
  addRolesSetting,
  editRolesSetting,
  delRoleById,
  // settingRoleTree,
  // editRolesTree,
  userGroup,
  setUserGroup,
} from "@/api/setting/roleSetting";
import regular from "@/functions/regular";
export default {
  components: {
    // "permissions-page": () => import("./permissions-page"),
    "role-permissions": () => import('./role-permissions')
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          { min: 1, max: 32, message: "长度是1-32个字符之间", trigger: "blur" },
          { validator: regular.heading, trigger: "blur" },
        ],
      },
      styleObj: {
        background: "#f5f7fa",
      },
      showRoleEdit: false, //显示后台权限页面
      showAddEditDraw: false,
      showGroup: false,
      // showRoleDrawer: false,
      roleTableData: [],
      userTotal: 0,
      currentPage: 1,
      selectedArr: [],
      selectedRoleArr: [],
      form: {
        name: "",
        remarks: "",
      },
      selectedGroupArr: [],
      // data: [],
      // defaultProps: {
      //   children: "children",
      //   label: "resourceName",
      // },
      // checkedAll: false,
      search: {
        pageNo: 1,
        pageSize: 10,
      },
      roleId: "",
      loading: false,
      // check: [],
      groupList: [
        { id: 1, name: "助贷CRM免费用户组", selected: 1 },
        { id: 2, name: "助贷CRM标准版用户组", selected: 0 },
      ],
      groupRoleInfo: "",
      rolePermession:{}
    };
  },
  created() {
    this.getRolesList();
  },
  mounted() {},
  methods: {
    // 获取角色列表
    getRolesList() {
      rolesSettingList(this.search).then((res) => {
        if (res.code == 0) {
          this.loading = true;
          this.roleTableData = res.data.list;
          this.userTotal = res.data.total;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getRolesList();
    },
    handleCurrentChange(val) {
      this.search.pageNo = val;
      this.currentPage = val;
      this.getRolesList();
    },
    handleSelectionChange(val) {
      this.selectedArr = val;
    },
    // 添加编辑右滑框按钮事件
    addOrEditRole(row) {
      this.showAddEditDraw = true;
      if (row.id) {
        this.$nextTick(() => {
          this.form = JSON.parse(JSON.stringify(row));
        });
      }
    },
    // 右滑左抽屉的关闭事件
    handleClose() {
      this.form.id = "";
      this.$refs.form.resetFields();
      this.showAddEditDraw = false;
    },
    // 创建添加角色或者编辑角色
    submitAddOrEdit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 表单存在id就是编辑否则就是新增
          if (this.form.id) {
            let data = {
              roleId: this.form.id,
              name: this.form.name,
              remarks: this.form.remarks,
            };
            editRolesSetting(data).then((res) => {
              if (res.code == 0) {
                this.$message.success("修改角色成功");
                this.handleClose();
                this.getRolesList();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            let data = {
              name: this.form.name,
              remarks: this.form.remarks,
            };
            addRolesSetting(data).then((res) => {
              if (res.code == 0) {
                this.$message.success("添加角色成功");
                this.handleClose();
                this.getRolesList();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 单个删除
    roleDelete(row) {
      delRoleById({ roleIds: [row.id] }).then((res) => {
        if (res.code == 0) {
          this.$message.success("角色删除成功");
          this.getRolesList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 多选删除
    delMore() {
      let ids = this.selectedArr.map((item) => item.id);
      delRoleById({ roleIds: ids }).then((res) => {
        if (res.code == 0) {
          this.$message.success("角色删除成功");
          this.getRolesList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleGroupClose() {
      this.showGroup = false;
    },
    // 展示用户组权限
    handleGroup(row) {
      console.log(row);
      this.showGroup = true;
      this.getGroupList(row);
    },
    // 展示后台权限
    handleGroupRole(row) {
      console.log(row);
      this.showRoleEdit = true;
      this.rolePermession = row
    },
    // 后台权限返回
    back(val) {
      this.showRoleEdit = val;
    },
    // 设置权限
    // handleRole(row) {
    //   this.roleId = row.id;
    //   this.showRoleDrawer = true;
    //   settingRoleTree({ roleId: this.roleId }).then((res) => {
    //     if (res.code == 0) {
    //       this.data = res.data.list;
    //       let selected = this.findAllSelect(this.data);
    //       let newArr = selected.reduce((pre, cur) => {
    //         if (!pre.includes(cur)) {
    //           return pre.concat(cur);
    //         } else {
    //           return pre;
    //         }
    //       }, []);
    //       this.$nextTick(() => {
    //         newArr.forEach((item) => {
    //           var node = this.$refs.tree.getNode(item);
    //           if (node.isLeaf) {
    //             this.$refs.tree.setChecked(node, true);
    //           }
    //         });
    //       });
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    // findAllSelect(arr) {
    //   arr.forEach((item) => {
    //     if (item.selected == 1) {
    //       this.check.push(item.permissionId);
    //     }
    //     if (item.children && item.children.length > 0) {
    //       if (item.selected == 1) {
    //         this.check.push(item.permissionId);
    //       }
    //       this.findAllSelect(item.children);
    //     }
    //   });
    //   return this.check;
    // },
    // 关闭权限抽屉
    // handleRoleClose() {
    //   this.showRoleDrawer = false;
    //   this.check = [];
    //   this.resetSelect();
    // },
    // 设置选权限中以及半选的值
    // handleCheckChange() {
    //   let selected = this.$refs.tree
    //     .getCheckedNodes()
    //     .concat(this.$refs.tree.getHalfCheckedNodes());
    //   this.selectedRoleArr = selected;
    //   let count = 0;
    //   this.data.forEach((item) => {
    //     count = count + 1;
    //     if (item.children && item.children.length > 0) {
    //       item.children.forEach((v) => {
    //         count = count + 1;
    //         if (v.children) {
    //           count = count + v.children.length;
    //         }
    //       });
    //     }
    //   });
    //   if (selected.length != count) {
    //     this.checkedAll = false;
    //   } else {
    //     this.checkedAll = true;
    //   }
    // },
    // resetSelect() {
    //   this.$refs.tree.setCheckedKeys([]);
    // },
    // handleCheckAllChange(val) {
    //   if (val) {
    //     this.$refs.tree.setCheckedNodes(this.data);
    //   } else {
    //     this.resetSelect();
    //   }
    // },
    // 保存权限信息
    // saveRole() {
    //   let arr = this.selectedRoleArr.map((item) => item.permissionId);
    //   editRolesTree({ roleId: this.roleId, permissionIds: arr }).then((res) => {
    //     if (res.code == 0) {
    //       this.$message.success("已成功设置权限");
    //       this.handleRoleClose();
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    getGroupList(row) {
      userGroup({ roleId: row.id }).then((res) => {
        if (res.code == 0) {
          this.groupList = res.data.list;
          this.groupList.forEach((item) => {
            if (item.selected == 1) {
              this.selectedGroupArr.push(item);
            }
          });
          this.groupRoleInfo = row;
        }
      });
    },
    selectedGroup(item) {
      let isHas = this.selectedGroupArr.indexOf(item);
      if (isHas == -1) {
        this.selectedGroupArr.push(item);
      } else {
        this.selectedGroupArr.splice(isHas, 1);
      }
    },
    submitGroup() {
      let ids = this.selectedGroupArr.map((item) => item.id);
      let data = {
        roleId: this.groupRoleInfo.id,
        groupIds: ids,
      };
      setUserGroup(data).then((res) => {
        if (res.code == 0) {
          (this.showGroup = false), (this.selectedGroupArr = []);
          this.$message.success("设置应用用户组权限成功");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card-box {
  margin: 10px;
  height: calc(100vh - 170px);
}
/deep/ .el-drawer:focus {
  outline: none;
}
/deep/ .el-drawer__header {
  margin: 0;
  padding: 0;
  margin: 20px 20px 20px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  // padding: 0;
  span {
    outline: none;
    font-weight: 600;
    color: #000;
  }
}
.drawer-content {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
  height: 100%;
  .el-form,
  .el-tree-warp {
    flex: 1;
    height: 0;
    overflow: auto;
    .checkItem {
      margin-bottom: 10px;
    }
  }
  .tree-area {
    border: 1px solid #ccc;
    margin: 20px 43px;
    border-radius: 8px;
    padding: 20px;
    min-height: 500px;
  }
  .drawer-footer {
    display: flex;
    justify-content: center;
    // .el-button {
    //   // flex: 1;
    // }
  }
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>

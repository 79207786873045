import request from '../request.js';
import public_request from '../public_request'
//获取后台角色列表
export function rolesSettingList({pageNo,pageSize}) {
    console.log(pageNo,pageSize);
    return request({
        url: '/setting/roles',
        method: 'get',
        params: public_request({
            pageNo,
            pageSize
        })
    })
}
// 新增后台角色
export function addRolesSetting(data) {
    return request({
        url: '/setting/role',
        method: 'post',
        data: public_request(data)
    })
}

// 编辑后台角色
export function editRolesSetting(data) {
    return request({
        url: '/setting/role',
        method: 'put',
        data: public_request(data)
    })
}

// 编辑后台权限
export function editRolesTree(data) {
    return request({
        url: '/setting/role-permissions-tree',
        method: 'put',
        data: public_request(data)
    })
}

// 删除后台角色
export function delRoleById(data) {
    return request({
        url: '/setting/roles',
        method: 'DELETE',
        data: public_request(data)
    })
}

// 获取角色的树形权限
export function settingRoleTree(data) {
    return request({
        url: '/setting/role-permissions-tree',
        method: 'get',
        params: public_request(data)
    })
}

// 获取后台角色应用用户组权限
export function userGroup(data) {
    return request({
        url: '/setting/role-app-user-groups',
        method: 'get',
        params: public_request(data)
    })
}

// 设置后台角色应用用户组权限
export function setUserGroup(data) {
    return request({
        url: '/setting/role-app-user-groups',
        method: 'put',
        data: public_request(data)
    })
}
// 获取后台角色应用权限列表
export function applicationPermissionList(data) {
    return request({
        url: '/setting/role-permissions-application',
        method: 'GET',
        params: public_request(data)
    })
}
// 设置应用用户组应用权限
export function setApplicationPermission(data) {
    return request({
        url: '/setting/role-permissions-application',
        method: 'PUT',
        data: public_request(data)
    })
}
// 获取应用用户组页面权限列表
export function groupPageList(data) {
    return request({
        url: '/setting/role-permissions-page',
        method: 'GET',
        params: public_request(data)
    })
}
// 设置应用用户组页面权限
export function setGroupList(data) {
    return request({
        url: '/setting/role-permissions-page',
        method: 'PUT',
        data: public_request(data)
    })
}
// 获取应用用户组元素权限列表
export function pageElementPermission(data) {
    return request({
        url: '/setting/role-permissions-page-element',
        method: 'GET',
        params: public_request(data)
    })
}

// 获取应用用户组元素权限列表
export function setPageElementPermission(data) {
    return request({
        url: '/setting/role-permissions-page-element',
        method: 'put',
        data: public_request(data)
    })
}

// 获取应用用户组菜单权限列表
export function menusList(data) {
    return request({
        url: '/setting/role-permissions-menu',
        method: 'GET',
        params: public_request(data)
    })
}
// 设置应用用户组菜单权限
export function setMenus(data) {
    return request({
        url: '/setting/role-permissions-menu',
        method: 'PUT',
        data: public_request(data)
    })
}

// 获取应用用户组功能权限列表
export function groupFunction(data) {
    return request({
        url: '/setting/role-permissions-function',
        method: 'GET',
        params: public_request(data)
    })
}
// 获取应用用户组功能权限列表
export function setGroupFunction(data) {
    return request({
        url: '/setting/role-permissions-function',
        method: 'PUT',
        data: public_request(data)
    })
}

